$mrtest $mol_book2_catalog
	title \Mr. Test Playground
	plugins /
		<= Theme $mol_theme_auto
	pages /
		<= Menu $mol_page
			Logo <= LogoMenu $mol_icon_mustache
			title \Мистер Тест
			tools <= menu_tools /
			body <= menu_body /
			foot /
				<= Profile_link $mol_link
					hint \Профиль
					arg *
						profile <= person_id \
						workspace null
					sub /
						<= Profile_icon $mol_icon_account
				<= Status $hyoo_crus_status
				<= Lighter $mol_lights_toggle
					Icon <= Lights_icon $mol_icon_brightness_4
		<= Source_page $mol_page
			Logo <= LogoSource $mol_icon_xml
			title \
			tools /
				<= Test_title $mol_string_button
					value? <=> test_title? \
					enabled <= test_editable false
				<= Selected_language $mol_select
					filter_hint \Выберите язык...
					value? <=> selected_langauge? \mrtest
					dictionary <= available_languages *
						mrtest \Mr. Test
						postman \Postman
						- python_aiohttp_pytest \Python + Aiohttp + Pytest
			body <= source_page_body /$mol_view
		<= Output_page $mol_page
			Logo <= LogoOutput $mol_icon_text_box
			title \Результат
			tools /
				<= Settings_pop $mol_pick
					hint \Нажмите для открытия настроек
					trigger_content /
						<= Options_trigger_icon $mol_icon_cog
					bubble_content /
						<= Settings_menu $mol_list rows /
							<= Print_source $mol_check_box
								title \Включать исходный код
								checked? <=> need_print_source? true
				<= Copy_result $mol_button_copy
					text? <=> result_to_copy? \
			body /
				<= Output $mol_textarea
					enabled false
					value? <=> output_code? \
	Source $mol_textarea
		hint \Введите описание теста на языке MrTest
		value? <=> source_code? \
		enabled <= test_editable
	Source_postman $mol_list
		rows <= validators_items /
	Validator* $mol_list
		sub /
			<= Validator_title_row* $mol_row
				sub /
					<= Validator_title* $mol_string_button
						hint \Введите название валидатора...
						value? <=> validator_title*? \
						enabled? <= workspace_editable? false
					^ validator_delete_button* / <= Validator_delete* $mol_button_minor
						click? <=> validator_delete*? null
						sub /
							<= Validator_delete_icon* $mol_icon_delete
			<= Validator_source* $mol_textarea
				hint \Введите код валидатора по аналогии с другими...
				value? <=> validator_source*? \
				enabled? <= workspace_editable?
	-
	Validator_header $mol_text
		text \**Валидаторы**
	-
	Validators_list $mol_list
		sub <= validators_list /$mol_view
	-
	Add_validator $mol_button_minor
		click? <=> add_validator? null
		sub /
			<= Add_validator_icon $mol_icon_plus
			\Добавить валидатор
	-
	Test_item* $mol_row
		sub <=> test_item_sub* /
	-
	Test_item_link* $mol_button_minor
		title? <=> test_item_title*? \Новый тест
		click? <= test_selected*? null
		style *
			color <= highlight_test_item_link* \var(--mol_theme_control)
		sub /
			<= Test_item_title* $mol_dimmer
				haystack <= test_item_title*? \Новый тест
				needle <=> search_filter \
	-
	Test_item_options* $mol_pick
		hint \Параметры теста
		trigger_content /
			<= Test_options_trigger_icon* $mol_icon_dots_vertical
		bubble_content /
			<= Test_options_content* $mol_list rows /
				<= Test_delete* $mol_button_minor
					click? <=> test_delete*? null
					sub /
						<= Delete_icon* $mol_icon_delete_outline
						\Удалить
	-
	Profile_page* $mrtest_person_page
		close_arg *
			profile null
		person <= profile* null
	-
	Access_page $mrtest_workspace_access
		workspace <= current_workspace null
		close_arg * access null
	-
	Create_test_button $mrtest_ui_button
		click? <=> test_make? null
		sub /
			<= AddButtonIcon $mol_icon_plus
			\Создать тест
	-
	Workspaces_list $mol_list
		rows <= tests_list /
	-
	Search $mol_link
		arg * search \
		sub / <= Search_icon $mol_icon_magnify
		style *
			color <= search_highlight \
	-
	Search_input $mol_string
		hint \Поиск...
		value? <=> search_filter? \
	-
	Access_link $mol_link
		hint @ \Права доступа
		arg *
			access \
			workspace null
		sub /
			<= Access_icon $mol_icon_security
	-
	Add_workspace $mol_button_minor
		hint \Создать рабочее пространство
		click? <=> workspace_make? null
		sub / <= Add_icon $mol_icon_plus
